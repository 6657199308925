@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body, html {
    font-family: 'Poppins', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    max-width: 1500px;
    -webkit-font-smoothing: antialiased;
    background: rgb(28, 27, 27);
    margin: 0 auto;
}

.bounce{
    animation-name: bounce;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-fill-mode: both;
}

@keyframes bounce {
    0%{
        margin-bottom: 0;
    }
    25%{
        margin-bottom: .5rem;
    }
    50%{
        margin-bottom: 1rem;
    }
    100%{
        margin-bottom: 1.5rem;
    }
}
.bouncea{
    
}

@keyframes bouncea {
   
}