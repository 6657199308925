=relative()
    position: relative
=absolute($top: null, $left: null, $right: null, $bottom: null)
    position: absolute
    top: $top
    bottom: $bottom
    right: $right
    left: $left
=fixed($top: null, $left: null, $right: null, $bottom: null)
    position: fixed
    top: $top
    bottom: $bottom
    right: $right
    left: $left
=static()
    position: static
=sticky($top: 0)
    position: sticky
    top: $top
=unset()
    position: unset
=top($top)
    top: $top
=bottom($bottom)
    bottom: $bottom
=left($left)
    left: $left
=right($right)
    right: $right

=absolutelyCentered()
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
=verticallyCentered($left: null)
    position: absolute
    top: 50%
    left: $left
    transform: translateY(-50%)
=horizontallyCentered($top: null)
    position: absolute
    top: $top
    left: 50%
    transform: translateX(-50%)
