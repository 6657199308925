@import '../../Utils/index'

.Finance
    .parent
        +grid(5.5rem)
        background-image: linear-gradient(to top left, black, black, black, $blue, $blue)
        +minHeight(35rem)
        +color(white)
        +p(5rem)
        +gtc(1fr 2fr)
        @media (max-width: 1100px) 
            +p(3rem 1rem)
            +gtc(1fr)
        .left
            +dlRadius(2rem)
            +height(27rem)
            +bg(blue)
            @media (max-width: 450px) 
                +height(22rem)
            #left
                +container(100%, 100%)
                +shadow(3px 3px 5px 2px $gray-900)
                +ml(1.5rem)
                +hidden
                +mt(1.5rem)
                +dlRadius(2rem)
                @media (max-width: 1100px) 
                    +ml(0)
                img
                    +coverImage
        .right
            +columnFlex(2rem)
            @media (max-width: 450px) 
                +columnFlex(1.5rem)
            .text
                &>:nth-child(1)
                    +font(2rem, null, 600)
                    +mb(1rem)
                &>:nth-child(2)
                    +mb(1rem)
                    +font(20px, null, 600)
                    +maxWidth(20rem)
                    
                &>:nth-child(3)
                    +maxWidth(30rem)
                    +mb(1rem)
                    +font(13px, null)
            .wrapper
                +flex(1rem, null, flex-start)
                .icon
                    svg
                        +size(2rem)
                @media (max-width: 450px)
                    +flex(1rem) 
                .textwrapper
                    +columnFlex(.5rem, space-between)
                    .title
                        +font(14px, null, 600)
                    .sub
                        +font(13px, null)
            