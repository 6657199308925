@import '../../Utils/index'


.Main
    background-image: linear-gradient(to bottom right, black, black, black, $blue, $blue)
    +index(10)
    +relative
    .parent
        +color(white)
        .main
            +grid()
            +p(7rem 5rem)
            +gtc(1fr 1fr)
            @media (max-width: 1200px) 
                +grid(3rem)
                +p(4rem 2rem 1rem)
                +gtc(1fr)
            @media (max-width: 600px) 
                +p(4rem 1rem 1rem)
            .left
                +columnFlex(2rem)
                &>:nth-child(1)
                    +font(1rem, $blue, 600)
                &>:nth-child(2)
                    +maxWidth(35rem)
                    +font(3.5rem, null, 600)
                    @media (max-width: 750px) 
                        +font(2.7rem, null, 600)
                        +spacing(null, null, 4rem)
                        +maxWidth(30rem)
                        span
                            +bb(none)
                    span
                        +bb(5px solid $hotpink)
                        +color($hotpink)
                        +pb(.2rem)
                       
                &>:nth-child(3)
                    +maxWidth(30rem)
                    +font(18px, $gray-300)
                    @media (max-width: 750px) 
                        +font(1rem, grey)
                        +spacing(null, null, 1.5rem)

                &>:nth-child(4)
                    +flex(1rem)
                    &>*
                        +p(1rem 2rem)
                        +pointer
                        +font(14px, null, 600)
                        @media (max-width: 750px) 
                            +font(12px, null, 600)
                            +p(.7rem 1.5rem)

                    &>:nth-child(1)
                        +radius(100vmax, 2px solid $hotpink)
                        +transition5
                        +bg($hotpink, white)
                        &:hover
                            +radius(100vmax, 2px solid $hotpink)
                            +bg(transparent, white)
                    &>:nth-child(2)
                        +transition5
                        +bg(transparent, $blue)
                        +radius(100vmax, 2px solid $blue)
                        &:hover
                            +bg(transparent, white)
                            +radius(100vmax, 2px solid white)
            
            
            
            
            
            
            
            
            
            
            .right
                +relative
                +index(10)
                +centerText
                .chain
                    +coverImage
                    animation-name: bounced
                    animation-duration: 2.5s 
                    animation-fill-mode: both
                    animation-iteration-count: infinite 
                    animation-direction: alternate 
                    @keyframes bounced 
                        0% 
                            top: 0 
                        100% 
                            top: 1.5rem 
                            +scale(1.1, 1.1)
                .header
                    +font(15px, $hotpink, 700)
                
                .text
                    +font(1.5rem, white, 800)
                
                .wrapper
                    +absolute($top: 0, $left: 0)
                    +columnFlex(.5rem)
                    animation-name: bounce
                    animation-duration: 2.5s 
                    animation-fill-mode: both
                    animation-iteration-count: infinite 
                    animation-direction: alternate 
                    @keyframes bounce  
                        0% 
                            top: 0 
                        100% 
                            top: 1.5rem 
                            +scale(1.1, 1.1)
                    @media (max-width: 600px) 
                        +none

                .wrapper2
                    +absolute($bottom: 0, $left: 0)
                    +columnFlex(.5rem)
                    animation-name: bouncec
                    animation-duration: 2.5s 
                    animation-fill-mode: both
                    animation-iteration-count: infinite 
                    animation-direction: alternate 
                    @keyframes bouncec 
                        0% 
                            bottom: 0 
                        100% 
                            +scale(1.1, 1.1)
                            bottom: 1rem 
                    @media (max-width: 600px) 
                        +none

                .wrapper3
                    +absolute($top: 0, $right: 0)
                    +columnFlex(.5rem)
                    animation-name: bounce
                    animation-duration: 2.5s 
                    animation-fill-mode: both
                    animation-iteration-count: infinite 
                    animation-direction: alternate 
                    @keyframes bounce  
                        0% 
                            top: 0 
                            +scale(1.1, 1.1)
                        100% 
                            top: 1.5rem 
                    @media (max-width: 600px) 
                        +none

    .hidden
        +pin(5rem)
        +pbl(2rem)
        +none
        +relative
        +index(1)
        +centerText
        @media (max-width: 1200px) 
            +pin(2rem)
        @media (max-width: 768px) 
            +pin(1rem)
        @media (max-width: 600px) 
            +flex(1rem, space-between, center)
        .hidden-header
            +font(.8rem, $hotpink, 600)
        .hidden-text
            +font(1rem, white, 600)
        .hidden-wrapper
            +columnFlex(.5rem)
            



            
               