@import '../../Utils/index'


.Testimonial
    .parent
        background-image: linear-gradient(to bottom left, black, black, black, $blue, $blue)
        +p(5rem)
        +color(white)
        +columnFlex(4rem)
        .head
            +columnFlex(1rem)
            +centerText
            .services
                +font(1.5rem, white, 700)
                @media screen and ( max-width: 768px )
                    +font(1.2rem, white, 700)
            .header
                +font(2.5rem, white, 700)
                @media screen and ( max-width: 768px )
                    +font(2rem, white, 700)
                    
            .lorem
                +font(.9rem, white, 500)
                +width(90%)
                margin: 0 auto
        @media (max-width: 1200px) 
            +pin(2rem)
        @media (max-width: 600px) 
            +pin(1rem)
        .body
            .cards
                +grid(2rem)
                +gtc(repeat(auto-fit, minmax(300px, 1fr)))
                .card
                    +p(1rem 1.5rem)
                    +pointer
                    background-image: linear-gradient(to bottom left, $hotpink, $blue, $blue)
                    +shadow(3px 3px 5px 2px $gray-900)
                    +columnFlex(1.5rem)
                    +transition5
                    +radius(.5rem)
                    .text
                        +font(14px, lightgrey)
                    .wrapper
                        +flex(1rem, space-between, center)
                        .cont
                            +flex(1rem, null, center)
                            .circle
                                +circle(60px)
                                +hidden
                                img
                                    +coverImage
                            .cover
                                +columnFlex(.1rem)
                                .name
                                    +font(14px, white, 600)
                                .post
                                    +font(14px, lightgrey, 500)
                                    
                            .name
                        svg
                            +size(2.5rem)
                    &:hover
                        +translateY(-1rem)
                        +bg(black)
                        +shadow(3px -3px 5px 2px black)
