
=grid($gap: null, $justify: null, $align: null, $flow: null, $cgap: null, $rgap: null)
    display: grid
    gap: $gap
    justify-content: $justify
    align-content: $align
    grid-auto-flow: $flow
    column-gap: $cgap
    row-gap: $rgap
=columnGrid($gap: null, $justify: null, $align: null, $cgap: null, $rgap: null)
    display: grid
    grid-auto-flow: column
    gap: $gap
    justify-content: $justify
    align-content: $align
    column-gap: $cgap
    row-gap: $rgap
=rowGrid($gap: null, $justify: null, $align: null, $cgap: null, $rgap: null)
    display: grid
    grid-auto-flow: row
    gap: $gap
    justify-content: $justify
    align-content: $align
    column-gap: $cgap
    row-gap: $rgap
=gtc($gtc)
    grid-template-columns: $gtc
=gtr($gtr)
    grid-template-rows: $gtr
=gac($gac)
    grid-auto-columns: $gac
=gar($gar)
    grid-auto-rows: $gar
=gta($gta)
    grid-template-areas: $gta

=gridArea($area)
    grid-area: $area

=centerGrid($gap: null, $cgap: null, $rgap: null)
    display: grid
    justify-content: center
    gap: $gap
    column-gap: $cgap
    row-gap: $rgap
    align-content: center

    
=justifySelf($justify)
    justify-self: $justify
=alignSelf($align)
    align-self: $align
    
=justifyItems($justify)
    justify-items: $justify
=alignItems($align)
    align-items: $align

=gap($gap)
    gap: $gap
=rgap($gap)
    row-gap: $gap
=cgap($gap)
    column-gap: $gap