@import '../../Utils/index'

.PcNav
    nav
        +flex
        +p(2rem 5rem)
        @media (max-width: 1200px) 
            +p(2rem 2rem)
        @media (max-width: 600px) 
            +p(2rem 1rem 1rem)
        &>*
        .logo
            flex: 1
            +flex(0, flex-start, center)
            span
                +font(1.5rem, white, 700)
                +pointer
                +transition5
                &:hover
                    +color($blue)
        .links
            +flex(null, flex-end)
            flex: 2
            ul
                +flex(2rem, space-between, center)
                +width(100%)
                +noList
                +weight(600)
                +pointer
                &>:last-child
                    +flex($wrap: mowrap)
                @media (max-width: 1000px) 
                    +none
                li
                    a
                        +noDecoration
                        +transition5
                        +relative
                        +font(13px, white, 600)
                        &::after
                            +circle(10px, black)
                            +transition5
                            +pseudo($top: 50%, $left: 105%)
                            +translateY(-50%)
                        &:hover
                            +color($hotpink)
                        &:hover::after
                            +bg($hotpink)
                            +pseudo($left: -20px, $top: 50%, )
                            .active::before
                        &::before
                            +bg(black)
                            +transition5
                            +pseudo($left: 0, $width: 0, $height: 3px,   $top: 120%, )
                    .active
                        +color($hotpink)
                        +scale(1.02, 1.02)
                        +relative
                    // .active::after
                    //     +bg($hotpink)
                    //     +pseudo($left: -20px, $top: 50%, )
                    .active::before
                        +bg($blue)
                        +transition5
                        +pseudo($left: 0, $width: 100%, $height: 3px,   $top: 120%, )