@import '../../Utils/index'


.Invest
    .parent
        +height(fit-content)
        background-image: linear-gradient(to bottom left, black, black, black, $blue, $blue)
        +p(5rem)
        +columnFlex()
        @media screen and ( max-width:1000px  )
            +p(3rem 2rem)
        @media screen and ( max-width:768px  )
            +p(3rem 1rem)
            
        .head
        .body
            +grid($cgap: 1rem,$rgap: 3rem)
            +color(white)
            +gtc(1fr 1fr)
            @media screen and ( max-width: 800px  )
                +gtc(1fr)
            .left
                +columnFlex(2rem, center)
                .bold
                    +font(2.5rem, white, 700)
                    +camelcase
                    @media screen and ( max-width: 1000px )
                        +font(1.5rem, white, 700)
                        
                .small
                    +font(1.2rem, white, 500)
                    +maxWidth(50ch)
                    +spacing(null, null, 1.5rem)
                    @media screen and ( max-width: 800px  )
                        +maxWidth(100%)
                .list
                    +weight(bold)
                    +columnFlex(1rem)
                    .item
                        +flex(1rem)
                        +font(1.2rem, white, 600)
                        @media screen and ( max-width: 1000px )
                            +font(1rem, white, 600)
                            +flex(.5rem)
                        .number
                        .text
                .btn
                    +p(.7rem 2rem)
                    +b(2px solid white)
                    +wfit
                    +radius(100vmax)
                    +pointer
                    +transition5
                    +bg(transparent, white)
                    &:hover
                        +color($hotpink)
                        +b(2px solid $hotpink)
            .right
                +flex(null, flex-end)
                @media screen and ( max-width:800px  )
                    +flex(null, flex-start, center)
                    grid-row: 1/2
                .pic
                    
                    +container(23rem, 100%)
                    +radius(.5rem)
                    +maxWidth(500px)
                    img
                        +radius(.5rem)
                        +coverImage
                        +hidden
                    @media screen and ( max-width:800px  )
                        +maxWidth(100%)
        