@import '../../Utils/index'


.MobileNav
    +relative
    +index(500)
    .parent
        +container(50px, 100%, $gray-900)
        +relative
        +index(500)
        .top
            +container(50px, 100%)
            background-image: linear-gradient(to bottom right, black, black, black, $blue, $blue)
            +fixed(0, 0)
            +index(69926)
            +pin(1rem)
            +flex(1rem, space-between, center)
            .logo
                +font(1.2rem, white, 800)
            .menuicon
                +pointer

        .bottom
            +container(calc(100vh - 50px), 100%, black)
            background-image: linear-gradient(to top right, black, black, black, $blue, $blue)
            +translate(-100vw, 100vh)
            +autoY
            +container(calc(100vh - 50px), 100%)
            +fixed(50px, 0)
            +index(62699)
            +index(1000)
            +transition8(ease-in-out)

            +p(2rem 1rem)
            ul
                +columnFlex(2rem)
                +noList
                +font(1rem, white, 600)
                li
                    +bb(2px solid white)
                    +pb(.5rem)
    .push
        +height(50px)
        +relative
