@import '../../Utils/index'


.Plans
    .parent
        // background-image: linear-gradient(to top right, black, black, black, $blue, $blue)
        +columnFlex(4rem)
        +bg(black)
        +p(4rem 0)
        @media screen and ( max-width: 80px )
            +columnFlex(2rem)
            
        .head
            +columnFlex(1rem)
            +centerText
            .services
                +font(1.5rem, white, 700)
                @media screen and ( max-width: 768px )
                    +font(1.2rem, white, 700)
            .header
                +font(2.5rem, white, 700)
                @media screen and ( max-width: 768px )
                    +font(2rem, white, 700)
                    
            .lorem
                +font(.9rem, white, 500)
                +width(90%)
                margin: 0 auto
        .body
            .cards
                +p(0 2rem)
                +grid(3rem, $justify: center)
                +gtc(repeat(auto-fit, minmax(220px, 1fr)))
                .card
                    +p(2rem 1.5rem)
                    +radius(.4rem)
                    +bg(white)
                    +pointer
                    +transition5
                    +shadow(3px 3px 3px 3px black)
                    min-width: 250px
                    +columnFlex(1rem)
                    .img
                        +size(2.5rem)
                    .plan
                        +uppercase
                        +font(1rem, null, 700)
                    .percentage
                        +font(1.3rem, null, 800)
                    .btn
                        +flex(.5rem, center, center)
                        +container(45px, 100%, $blue)
                        +radius(.2rem)
                        +mt(auto)
                        +shadow(2px 2px 5px 2px $hotpink)
                        +weight(500)
                        +transition5
                        +font(13px, white, 600)
                    .wrapper
                        +columnFlex(1rem, center, flex-start)
                        .wrapper1
                            +flex(.3rem, null, flex-start)
                            +font(12px, null, 600)
                            +uppercase
                            svg
                                +size(14px)
                    &:hover
                        +scale(.95, .95)
                        +color(white)
                        +shadow(3px -3px 3px 3px $gray-900)
                        background: linear-gradient(to top right, $hotpink, $blue)
                        
                    &:hover .btn
                    &:hover .wrapper
                        +weight(600)

            