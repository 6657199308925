@import '../../Utils/index'

.Nav
    .parentt
        .mobile
            +none
            @media (max-width: 1000px)  
                +block
        .pc
            +none
            @media (min-width: 1000px)  
                +block
            