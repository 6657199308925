=pseudo($content: '', $top: 0, $left: 0, $bottom: null, $right: null, $height: null, $width: null, $bg: null, $color: null, $radius: null)
    position: absolute
    content: $content
    top: $top
    left: $left
    bottom: $bottom
    right: $right
    height: $height
    width: $width
    background: $bg
    color: $color
    border-radius: $radius
