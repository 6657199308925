
=container($height, $width, $bg: null, $padding: null)
    height: $height
    width: $width
    background: $bg
    padding: $padding
=square($value, $bg: null, $padding: null)
    height: $value
    width: $value
    padding: $padding
    background: $bg
=height($height, $bg: null)
    height: $height
    background: $bg
=maxHeight($height, $bg: null)
    max-height: $height
    background: $bg
=minHeight($height, $bg: null)
    min-height: $height
    background: $bg
=width($width, $bg: null)
    width: $width
    background: $bg
=maxWidth($width, $bg: null)
    max-width: $width
    background: $bg
=minWidth($width, $bg: null)
    min-width: $width
    background: $bg
=circle($value, $bg: null, $padding: null)
    height: $value
    width: $value
    border-radius: 50%
    padding: $padding
    background: $bg
=setWidth($width: null, $bg: null, $padding: null)
    width: $width
    height: 100%
    background: $bg
    padding: $padding
=setHeight($height: null, $bg: null, $padding: null)
    width: 100%
    height: $height
    background: $bg
    padding: $padding
=setWidthV($width: null, $bg: null, $padding: null)
    width: $width
    height: 100vh
    background: $bg
    padding: $padding
=setHeightV($height: null, $bg: null, $padding: null)
    width: 100vw
    height: $height
    background: $bg
    padding: $padding
=fullPercentage($bg: null, $padding: null)
    height: 100%
    width: 100%
    background: $bg
    padding: $padding
=fullViewport($bg: null, $padding: null)
    height: 100vh
    width: 100vw
    background: $bg
    padding: $padding
=wfit()
    width: fit-content
=hfit()
    height: fit-content
=shadow($shadow)
    box-shadow: $shadow
=ratio( $width: null, $height: null, $ratio: 16/9)
    width: $width
    height: $height
    aspect-ratio: $ratio
