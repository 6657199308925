=m($margin)
    margin: $margin
=mt($margin)
    margin-top: $margin
=ml($margin)
    margin-left: $margin
=mr($margin)
    margin-right: $margin
=mb($margin)
    margin-bottom: $margin
=min($margin)
    margin-inline: $margin
=mbl($margin)
    margin-block: $margin
=mc()
    margin: 0 auto


=nom()
    margin: none
=nomt()
    margin-top: none
=noml()
    margin-left: none
=nomb()
    margin-bottom: none
=nomr()
    margin-right: none
=nomin()
    margin-inline: none
=nombl()
    margin-block: none
