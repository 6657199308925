=p($padding)
    padding: $padding
=pt($padding)
    padding-top: $padding
=pl($padding)
    padding-left: $padding
=pr($padding)
    padding-right: $padding
=pb($padding)
    padding-bottom: $padding
=pin($padding)
    padding-inline: $padding
=pbl($padding)
    padding-block: $padding

    
=nop()
    padding: 0
=nopt()
    padding-top: none
=nopl()
    padding-left: none
=nopb()
    padding-bottom: none
=nopr()
    padding-right: none
=nopin()
    padding-inline: none
=nopbl()
    padding-block: none
