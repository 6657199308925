=flex($gap: null, $justify: null, $align: null, $flow: null, $columnGap: null, $rowGap: null, $wrap: null)
    display: flex
    gap: $gap
    justify-content: $justify
    align-items: $align
    grid-auto-flow: $flow
    column-gap: $columnGap
    row-gap: $rowGap
    flex-wrap: $wrap
=columnFlex($gap: null, $justify: null, $align: null, $columnGap: null, $rowGap: null)
    display: flex
    flex-direction: column
    gap: $gap
    justify-content: $justify
    align-items: $align
    column-gap: $columnGap
    row-gap: $rowGap
=rowFlex($gap: null, $justify: null, $align: null, $columnGap: null, $rowGap: null)
    display: flex
    flex-direction: row
    gap: $gap
    justify-content: $justify
    align-items: $align
    column-gap: $columnGap
    row-gap: $rowGap
=centerFlex($gap: null)
    display: flex
    gap: $gap
    justify-content: center
    align-items: center
    
=gap($gap)
    gap: $gap
=rowGap($gap)
    row-gap: $gap
=columnGap($gap)
    column-gap: $gap

=flextl($gap: null)
    display: flex
    justify-content: flex-start
    align-items: flex-start
    gap: $gap
=flextr($gap: null)
    display: flex
    justify-content: flex-end
    align-items: flex-start
    gap: $gap
=flexbl($gap: null)
    display: flex
    justify-content: flex-start
    align-items: flex-end
    gap: $gap
=flexbr($gap: null)
    display: flex
    justify-content: flex-end
    align-items: flex-end
    gap: $gap

=flextlc($gap: null)
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    gap: $gap
=flextrc($gap: null)
    display: flex
    flex-direction: column
    justify-content: flex-end
    align-items: flex-start
    gap: $gap
=flexblc($gap: null)
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-end
    gap: $gap
=flexbrc($gap: null)
    display: flex
    flex-direction: column
    justify-content: flex-end
    align-items: flex-end
    gap: $gap

=wrap
    flex-wrap: wrap
=nowrap
    flex-wrap: nowrap