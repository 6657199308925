@import '../../Utils/index'


.Reviews
    .parent
        +p(5rem)
        +columnFlex(4rem)
        +bg(black)
        @media (max-width: 1200px) 
            +pin(2rem)
        @media (max-width: 600px) 
            +pin(1rem)
        .head
            +columnFlex(1rem)
            +centerText
            .services
                +font(1.5rem, white, 700)
                @media screen and ( max-width: 768px )
                    +font(1.2rem, white, 700)
            .header
                +font(2.5rem, white, 700)
                @media screen and ( max-width: 768px )
                    +font(2rem, white, 700)
                    
            .lorem
                +font(.9rem, white, 500)
                +width(95%)
                margin: 0 auto
        .body
            +columnFlex(2rem)
            .cards
                +grid(2rem)
                +gtc(repeat(auto-fit, minmax(250px, 1fr)))
                .card
                    +p(1rem)
                    +centerText
                    +columnFlex(1.5rem, center, center)
                    .circle
                        +circle(70px, $blue)
                    .stars
                        +flex(1rem, center, center)
                        svg
                            +color(orange)
                    .text
                        +color(whitesmoke)
                    .name
                        +mt(1rem)
                        +font(15px, white, 600)
            .btn-section
                +centerFlex
                .btn
                    +p(.8rem 2rem)
                    +pointer
                    +font(15px, white, 600)
                    +radius(100vmax)
                    +b(2px solid white)
                    +transition5
                    &:hover
                        +color($hotpink, 2px solid $hotpink)

