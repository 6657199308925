@import '../../Utils/index'


.Card
    .parent
        background-image: linear-gradient(to top right, black, black, black, $blue, $blue)
        +transition5
        +p(5rem)
        +columnFlex(4rem)
        .head
            +columnFlex(1rem)
            +centerText
            .services
                +font(1.5rem, white, 700)
                @media screen and ( max-width: 768px )
                    +font(1.2rem, white, 700)
            .header
                +font(2.5rem, white, 700)
                @media screen and ( max-width: 768px )
                    +font(2rem, white, 700)
                    
            .lorem
                +font(.9rem, white, 500)
                +width(95%)
                margin: 0 auto
        .body
            .cards
                +transition5
                +grid(2rem)
                +gtc(repeat(auto-fit, minmax(300px, 1fr)))
                .card
                    +p(2rem 1rem)
                    +pointer
                    +flex(1rem, flex-start, flex-start)
                    +shadow(3px 3px 5px 2px $gray-900)
                    +transition5
                    +radius(.5rem)
                    +bg(whitesmoke)
                    &>:nth-child(1)
                        +size(50px)
                        +pb(1rem)
                        +color($blue)
                        +transition5
                    .wrapper
                        +columnFlex(.5rem)
                        +color($blue)
                        &>:nth-child(1)
                            +font(1rem, black, 600)
                            +transition5
                        &>:nth-child(2)
                            +transition5
                            +font(.8rem, grey, 400)
                        &>:nth-child(3)
                            +size(12px)
                            +flex(.5rem, flex-start, center)
                            .right
                                +transition(2000ms)
                                +size(1rem)
                    &:hover
                        +scale(1.1, 1.1)
                        +bg(transparent)
                        +shadow(3px -3px 5px 4px $hotpink)
                        +radius(20%)
                        @media (max-width: 1000px) 
                            +scale(1, 1)
                    &:hover > svg
                        +ml(2rem)
                        +color($hotpink)
                    &:hover > .wrapper > .title
                        +color(white)
                    &:hover > .wrapper > .sub
                        +color(white)
                    &:hover > .wrapper > .cont > .right
                        +ml(4rem)
                        +color(white)
                        @media (max-width: 1000px) 
                            +ml(0)
                    &:hover > .wrapper > .cont > span
                        +color(white)
                    
        @media (max-width: 1200px) 
            +pin(2rem)
        @media (max-width: 600px) 
            +pin(1rem)