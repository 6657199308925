@import '../../Utils/index'

.Error
    +fullViewport(white)
    +centerFlex
    background-image: linear-gradient(to bottom right, black, black, black, $blue, $blue)
    +centerText
    +color(white)
    .body
        +columnFlex(2rem)
        +centerText
        h1
            animation-name: bouncess
            animation-duration: 3s 
            animation-fill-mode: both
            animation-iteration-count: infinite 
            animation-direction: alternate 
            @keyframes bouncess  
                0% 
                    +color($hotpink)
                50% 
                    +color($blue)

                100% 
                    +color($red)
                    +scale(1.1, 1.1)
        &>:nth-child(3)
            +centerFlex(.4rem)
            a
                +noDecoration
                +color($hotpink)
            
            